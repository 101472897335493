import React from 'react'
import { Button, ButtonGroup, Form, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { ArrowRepeat, PlusSlashMinus, Trash } from 'react-bootstrap-icons';
import '../pages/styles/HomeButtonBar.css'

const HomeButtonBar = ({
    handleHistoryClick,
    handleReset,
    handleRemodel,
    isResetting,
    isRecalculating,
    isRemodeling,
    handleRecalculate,
    handleOpenDrivers,
    recalculationDone,
    renderTooltip,
    handleSubmit,
    changesMade,
    standbyListUsed,
    vertederoParameter,
    setVertederoParameter
}) => {
    const renderVertederoTooltip = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Haga clic en Restaurar para ver los cambios
        </Tooltip>
    );

    const handleSelectVertederoParameter = (event) => {
        setVertederoParameter(event.target.value);
    };

    return (
        <>
            <OverlayTrigger
                placement="left"
                delay={{ show: 100, hide: 400 }}
                overlay={renderVertederoTooltip}
            >
                <Form.Select
                    className="me-3"
                    style={{ maxWidth: '14rem' }}
                    aria-label="Vertederos"
                    onChange={handleSelectVertederoParameter}
                    value={vertederoParameter}
                >
                    <option value="cheapest">Vertedero más barato</option>
                    <option value="closest">Vertedero más cercano</option>
                </Form.Select>
            </OverlayTrigger>

            <ButtonGroup className="me-3 button-group-custom">
                <Button className="btn-action" onClick={handleHistoryClick}>
                    Historial
                </Button>

                <Button
                    className="btn-action"
                    onClick={handleReset}
                    disabled={isRemodeling || isRecalculating || isResetting}
                >
                    {isResetting ? (
                        <>
                            <Spinner animation="border" size="sm" />
                            <span className="ms-2">Restaurando...</span>
                        </>
                    ) : (
                        <Trash size={24} />
                    )}
                </Button>

                <Button
                    className="btn-action"
                    onClick={handleRecalculate}
                    disabled={!changesMade || isRecalculating || isResetting || isRemodeling}
                >
                    {isRecalculating ? (
                        <>
                            <Spinner animation="border" size="sm" />
                            <span className="ms-2">Recalculando...</span>
                        </>
                    ) : (
                        <PlusSlashMinus size={24} />
                    )}
                </Button>

                <Button
                    onClick={handleRemodel}
                    className="btn-action"
                    disabled={!standbyListUsed || isRecalculating || isResetting || isRemodeling}
                >
                    {isRemodeling ? (
                        <>
                            <Spinner animation="border" size="sm" />
                            <span className="ms-2">Remodelando...</span>
                        </>
                    ) : (
                        <ArrowRepeat size={24} />
                    )}
                </Button>
            </ButtonGroup>

            <Button className="me-3 btn-action" onClick={handleOpenDrivers}>
                Choferes
            </Button>

            {(changesMade || isRecalculating || isResetting) ? (
                <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={renderTooltip}
                >
                    <div>
                        <Button className="btn-action" onClick={handleSubmit} disabled>
                            Enviar
                        </Button>
                    </div>
                </OverlayTrigger>
            ) : (
                <Button className="btn-action" onClick={handleSubmit} disabled={!recalculationDone}>
                    Enviar
                </Button>
            )}
        </>
    );
};

export default HomeButtonBar