import React from "react";
import { Card, Col, Row } from "react-bootstrap";

const TotalCostHome = ({ data, isFetching, firstIteration, selectedDate, handleOpenDrivers }) => {
    const calculateDelta = (current, original) => {
        if (!original) return { value: null, formatted: null };
        const delta = current - original;
        const formatted = `${delta >= 0 ? "+" : ""}${delta.toFixed(1)}`;
        return { value: delta, formatted: formatted };
    };

    const total_delta = firstIteration
        ? calculateDelta(data.total_cost, firstIteration.total_cost)
        : { value: null, formatted: null };

    const time_delta = firstIteration
        ? calculateDelta(data.total_cost_per_hour, firstIteration.total_cost_per_hour)
        : { value: null, formatted: null };

    const distance_delta = firstIteration
        ? calculateDelta(data.total_cost_per_km, firstIteration.total_cost_per_km)
        : { value: null, formatted: null };

    return (
        <Row>
            <Card className="mt-3 mb-1 mx-3 card-primary">
                <Card.Body>
                    Costo por duración:
                    <Card.Title>
                        {isFetching ? (
                            <p className='pt-4'>Cargando...</p>
                        ) : data ? (
                            <>
                                ${Number(data.total_cost_per_hour).toFixed(1)}
                                {time_delta.value !== null && (
                                    <small
                                        className={time_delta.value <= 0 ? "benefit-positive ms-1" : "benefit-negative ms-1"}
                                    >
                                        ({time_delta.formatted})
                                    </small>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </Card.Title>
                </Card.Body>
            </Card>
            <Card className="mt-3 mb-1 mx-3 card-primary">
                <Card.Body>
                    Costo por distancia:
                    <Card.Title>
                        {isFetching ? (
                            <p className='pt-4'>Cargando...</p>
                        ) : data ? (
                            <>
                                ${Number(data.total_cost_per_km).toFixed(1)}
                                {distance_delta !== null && (
                                    <small
                                        className={distance_delta.value <= 0 ? "benefit-positive ms-1" : "benefit-negative ms-1"}
                                    >
                                        ({distance_delta.formatted})
                                    </small>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </Card.Title>
                </Card.Body>
            </Card>
            <Card className="mt-3 mb-1 mx-3 card-primary">
                <Card.Body>
                    Costo total:
                    <Card.Title>
                        {isFetching ? (
                            <p className='pt-4'>Cargando...</p>
                        ) : data ? (
                            <>
                                ${Number(data.total_cost).toFixed(1)}
                                {total_delta !== null && (
                                    <small
                                        className={total_delta.value <= 0 ? "benefit-positive ms-1" : "benefit-negative ms-1"}
                                    >
                                        ({total_delta.formatted})
                                    </small>
                                )}
                            </>
                        ) : (
                            <></>
                        )}
                    </Card.Title>
                </Card.Body>
            </Card>
            <Card className="mt-3 mb-1 mx-3 card-primary" onClick={handleOpenDrivers} style={{ cursor: 'pointer' }}>
                <Card.Body>
                    Fecha seleccionada:
                    <Card.Title>
                        {selectedDate ? selectedDate.toLocaleDateString('es-ES') : 'Aún no seleccionada'}
                    </Card.Title>
                </Card.Body>
            </Card>
        </Row>
    );
}

export default TotalCostHome;
