import React, { useState } from 'react';
import DraggableLists from '../components/DraggableLists.jsx';
import { Button, Col, Container, Row, Spinner, Tooltip } from 'react-bootstrap';
import { fetchStations } from '../api/fetchStations.js';
import { postRemodel } from '../api/postRemodel.js'
import { postRecalculate } from '../api/postRecalculate.js';
import { postEditPoints } from '../api/postEditPoints.js'
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../components/ErrorMessage.jsx';
import { postFinalData } from '../api/postFinalData.js';
import SelectDriversModal from '../components/SelectDriversModal.jsx';
import SubmitModal from '../components/SubmitModal.jsx';
import './styles/Home.css';
import img_logo from "../imgs/logo_azul.png";
import HomeButtonBar from '../components/HomeButtonBar.jsx';
import TotalCostHome from '../components/TotalCostHome.jsx';
import { PlayFill } from 'react-bootstrap-icons';
import { fetchDrivers } from '../api/fetchDrivers.js';

const Home = () => {
  const navigate = useNavigate();

  const [showNetworkError, setShowNetworkError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState(null);
  const [firstIteration, setFirstIteration] = useState(null);

  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [selectedDate, setSelectedDate] = useState("")

  const [history, setHistory] = useState([]);
  const [showModal, setShowModal] = useState(false);

  //Button bar
  const [vertederoParameter, setVertederoParameter] = useState('cheapest')
  const [isFetching, setIsFetching] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [isRecalculating, setIsRecalculating] = useState(false);
  const [isRemodeling, setIsRemodeling] = useState(false);
  const [standbyListUsed, setStandByListUsed] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // StandBy List
  const [standByList, setStandByList] = useState([]);

  // Select drivers
  const [showSelectDrivers, setShowSelectDrivers] = useState(false);

  const handleOpenDrivers = () => {
    setShowSelectDrivers(true);

    fetchDrivers()
      .then((data) => {
        setDrivers(data);
      })
      .catch((error) => {
        console.log("Error occurred while fetching drivers:", error.error);
        handleError(error);
      });
  };

  const handleCloseDrivers = () => {
    setShowSelectDrivers(false);
  };

  const handleFetchData = () => {
    setIsFetching(true);

    fetchStations(vertederoParameter, selectedDrivers, selectedDate)
      .then((fetchedData) => {
        setData(fetchedData);
        if (firstIteration === null) {
          setFirstIteration(fetchedData);
        }
      })
      .catch((error) => {
        console.log("Error occurred while fetching stations:", error.message);
        handleError(error);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const handleReset = () => {
    setIsResetting(true);
    fetchStations(vertederoParameter, selectedDrivers, selectedDate)
      .then((fetchedData) => {
        setFirstIteration(fetchedData);
        setChangesMade(false);
        setData(fetchedData);
        setIsResetting(false);
        setStandByList([])
      })
      .catch((error) => {
        setIsResetting(false);
        handleError(error);
      });
  };

  const handleRecalculate = () => {
    setIsRecalculating(true);
    setHistory((prevHistory) => [
      ...prevHistory,
      { timestamp: new Date(), data },
    ]);

    let hasNewName = false;

    data.stations.forEach((station) => {
      station.itinerary.forEach((itineraryItem) => {
        if (itineraryItem.new_name !== null) {
          hasNewName = true;
        }
      });
    });

    if (hasNewName) {
      postEditPoints(vertederoParameter, data, selectedDate)
        .then((newData) => {
          setChangesMade(false);
          setIsRecalculating(false);

          newData.stations.forEach((station) => {
            station.itinerary.forEach((itineraryItem) => {
              if (itineraryItem.new_name !== null) {
                itineraryItem.new_name = null;
              }
            });
          });
          setData(newData);
        })
        .catch((error) => {
          setIsRecalculating(false);
          handleError(error);
        });
    } else {
      postRecalculate(vertederoParameter, data, selectedDate)
        .then((newData) => {
          setData(newData);
          setChangesMade(false);
          setIsRecalculating(false);
        })
        .catch((error) => {
          setIsRecalculating(false);
          handleError(error);
        });
    }
  };

  const handleRemodel = () => {
    setIsRemodeling(true);
    setHistory((prevHistory) => [
      ...prevHistory,
      { timestamp: new Date(), data },
    ]);

    postRemodel(vertederoParameter, standByList, selectedDrivers)
      .then((newData) => {
        setData(newData);
        setIsRemodeling(false);
      })
      .catch((error) => {
        setIsRemodeling(false);
        handleError(error);
      });
  };

  const handleError = (error) => {
    if (error.message) {
      setErrorMessage(error.message);
    } else {
      setErrorMessage("Ocurrió un error al procesar la solicitud. Por favor, intente más tarde.");
    }
    setShowNetworkError(true);
  };

  const handleHistoryClick = () => {
    navigate("/history", { state: { history } });
  };

  const handleSubmit = () => {
    setShowModal(true);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Debe recalcular antes de enviar
    </Tooltip>
  );

  const handleCloseModal = () => {
    if (!isSubmitting) {
      setShowModal(false);
      setIsSubmitted(false);
      setErrorMessage("");
    }
  };

  const handleConfirmSubmit = () => {
    setIsSubmitting(true);
    const finalData = data.stations.map((station, index) => {
      const driver = drivers[index];
      return {
        ...station,
        driver_id: driver.id,
      };
    });

    postFinalData(finalData)
      .then((res) => {
        setIsSubmitting(false);
        setIsSubmitted(true);
      })
      .catch((err) => {
        setIsSubmitting(false);
        setErrorMessage("Ocurrió un error al enviar los datos.");
      });
  };

  return (
    <Container fluid style={{ padding: 0, margin: 0, maxWidth: '100vw', overflow: 'hidden' }}>
      <div className="header" style={{ boxShadow: '0 4px 4px 0 rgba(0,0,0,0.2)' }}>
        <Row className="align-items-center" style={{ margin: '5px' }}>
          <Col xs={12} md={4} className='img-logo'>
            <img src={img_logo} alt="Logo Azul" />
          </Col>
          <Col xs={12} md={8} className="d-flex justify-content-end">
            <HomeButtonBar
              handleHistoryClick={handleHistoryClick}
              handleReset={handleReset}
              handleRemodel={handleRemodel}
              isResetting={isResetting}
              isRecalculating={isRecalculating}
              handleRecalculate={handleRecalculate}
              handleOpenDrivers={() => setShowSelectDrivers(true)}
              standbyListUsed={standbyListUsed}
              renderTooltip={renderTooltip}
              handleSubmit={handleSubmit}
              changesMade={changesMade}
              vertederoParameter={vertederoParameter}
              setVertederoParameter={setVertederoParameter}
              isRemodeling={isRemodeling}
              setIsRemodeling={setIsRemodeling}
            />
          </Col>
        </Row>
        <TotalCostHome
          data={data}
          firstIteration={firstIteration}
          isFetching={isFetching}
          selectedDate={selectedDate}
          handleOpenDrivers={handleOpenDrivers}
        />
      </div>
      {data === null && !isFetching ? (
        <div className="text-center mt-4">
          <Button
            onClick={handleOpenDrivers}
            title="Haz clic para iniciar la carga de datos"
            className='btn-start'
            size='lg'
          >
            <PlayFill className="me-2" size={24} />
            Iniciar Proceso de Carga
          </Button>
        </div>
      ) : data === null && isFetching ? (
        <div className="text-center mt-4">
          <Spinner />
        </div>
      ) : (
        <Container className="px-4 container-bg" fluid style={{ overflowX: 'auto', whiteSpace: 'nowrap', padding: '0 10px' }}>
          <DraggableLists
            lists={data}
            setLists={(newData) => {
              setData(newData);
            }}
            setStandByListUsed={setStandByListUsed}
            setChangesMade={setChangesMade}
            firstIteration={firstIteration}
            drivers={drivers}
            setDrivers={setDrivers}
            standByList={standByList}
            setStandByList={setStandByList}
          />
        </Container>
      )}
      <ErrorMessage show={showNetworkError} onHide={() => setShowNetworkError(false)} errorMessage={errorMessage} />
      <SelectDriversModal
        show={showSelectDrivers}
        setShowSelectDrivers={setShowSelectDrivers}
        drivers={drivers}
        setDrivers={setDrivers}
        handleError={handleError}
        handleFetchData={handleFetchData}
        setSelectedDrivers={setSelectedDrivers}
        selectedDrivers={selectedDrivers}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <SubmitModal
        showModal={showModal}
        errorMessage={errorMessage}
        handleCloseModal={handleCloseModal}
        handleConfirmSubmit={handleConfirmSubmit}
        isSubmitted={isSubmitted}
        isSubmitting={isSubmitting}
      />
    </Container>
  );
};

export default Home;
