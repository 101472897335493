import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es'; // Import Spanish locale
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

registerLocale('es', es);

const SelectDriversModal = ({ show, setShowSelectDrivers, drivers, setSelectedDrivers, selectedDrivers, selectedDate, setSelectedDate, handleFetchData }) => {
    const [selectAll, setSelectAll] = useState(false);

    const handleSelectAll = () => {
        if (selectAll) {
            setSelectedDrivers([]);
        } else {
            setSelectedDrivers(drivers.map(driver => driver.id));
        }
        setSelectAll(!selectAll);
    };

    const handleDriverToggle = (driverId) => {
        if (selectedDrivers.includes(driverId)) {
            setSelectedDrivers(selectedDrivers.filter(id => id !== driverId));
        } else {
            setSelectedDrivers([...selectedDrivers, driverId]);
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    const handleStart = () => {
        handleFetchData();
        setShowSelectDrivers();
    }

    const isComenzarDisabled = selectedDrivers.length === 0 || !selectedDate;

    return (
        <Modal show={show} onHide={() => setShowSelectDrivers(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Seleccione choferes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {drivers ? (
                        <>
                            <Form.Check
                                label="Seleccionar todos"
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                            {drivers.map((driver) => (
                                <Form.Check
                                    key={driver.id}
                                    label={driver.name}
                                    checked={selectedDrivers.includes(driver.id)}
                                    onChange={() => handleDriverToggle(driver.id)}
                                />
                            ))}
                            <div style={{ marginTop: '20px' }}>
                                <div>
                                    <label>Seleccionar Fecha: </label>
                                    <DatePicker
                                        selected={selectedDate}
                                        onChange={handleDateChange}
                                        dateFormat="dd/MM/yyyy"
                                        locale="es"
                                        className="form-control"
                                        placeholderText="Selecciona una fecha"
                                        minDate={new Date()}
                                        maxDate={new Date().setDate(new Date().getDate() + 10)}
                                        filterDate={(date) => date.getDay() !== 0} // Exclude Sundays
                                    />
                                </div>
                            </div>
                        </>
                    ) : (
                        <Spinner />
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={handleStart}
                    disabled={isComenzarDisabled} // Disable button conditionally
                >
                    Comenzar
                </Button>
                <Button
                    variant="secondary"
                    onClick={() => setShowSelectDrivers(false)}
                >
                    Cerrar
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default SelectDriversModal